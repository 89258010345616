import { Category } from "./Category.dto";
import { ReportWithUser } from "./Report.dto";
import { SearchFilter } from "./SearchFilter.dto";

export enum WatchFaceType {
  Photo = 'photo',
  Portrait = 'portrait',
  Animated = 'animated',
  Complication = 'complication',
  Parmesan = 'parmesan',
}

export const WatchFaceTypeOptions = [
  {
    text: 'Photo',
    value: WatchFaceType.Photo,
  },
  {
    text: 'Portrait',
    value: WatchFaceType.Portrait,
  },
  {
    text: 'Animated',
    value: WatchFaceType.Animated,
  },
  {
    text: 'Complication',
    value: WatchFaceType.Complication,
  },
  {
    text: 'Parmesan',
    value: WatchFaceType.Parmesan,
  },
]

export enum WFTimeStyle {
  Photo = 'photo',
  Classic = 'classic',
  Modern = 'modern',
  Rounded = 'rounded',
}

export enum WFTimePosition {
  Top = 'top',
  Bottom = 'bottom',
  Custom = 'custom',
}

export enum WFStatus {
  Published = 'published',
  Unpublished = 'unpublished',
  Suspected = 'suspected',
  Banned = 'banned',
}

export const WFStatusOptions = [
  {
    text: 'Published',
    value: WFStatus.Published,
  },
  {
    text: 'Unpublished',
    value: WFStatus.Unpublished,
  },
  {
    text: 'Suspected',
    value: WFStatus.Suspected,
  },
  {
    text: 'Banned',
    value: WFStatus.Banned,
  },
]

export enum WFSeries {
  Series2AndLater = 'wf2andLower',
  Series3 = 'wf3',
  Series4 = 'wf4',
  Series5 = 'wf5',
  Series6 = 'wf6',
  Series7 = 'wf7',
  Series8 = 'wf8',
  Series9 = 'wf9',
  SeriesSE = 'wfSE',
  SeriesSE2 = 'wfSE2',
  SeriesUltra = 'wfULTRA',
  SeriesUltra2 = 'wfULTRA2'
}

export enum WFWidgetType {
  Off = 'off',

  TodaysDate = 'todaysDate',
  YourSchedule = 'yourSchedule',

  Compass = 'compass',
  CompassElevation = 'compassElevation',
  Elevation = 'elevation',

  Timer = 'timer',
  Timer1min = 'timer1min',
  Timer3min = 'timer3min',
  Timer5min = 'timer5min',
  Timer10min = 'timer10min',
  Timer15min = 'timer15min',
  Timer30min = 'timer30min',
  Timer1hr = 'timer1hr',
  Timer2hr = 'timer2hr',

  Wind = 'wind',
  Rain = 'rain',
  Weather = 'weather',
  UvIndex = 'uvIndex',

  Alarm = 'alarm',
  SunRiseSet = 'sunRiseSet',
  Activity = 'activity',
  MoonPhase = 'moonPhase',
  AudioBooks = 'audioBooks',
  Messages = 'messages',
  Music = 'music',
  Podcasts = 'podcasts',
  Workout = 'workout',
  Battery = 'battery',
  HeartRate = 'heartRate',
  SoundLevels = 'soundLevels',
  Reminders = 'reminders',
  Stopwatch = 'stopwatch',

  AirQuality = 'airQuality',
  FindDevices = 'findDevices',
  FindItems = 'findItems',
  FindPeople = 'findPeople',
  Humidity = 'humidity',
  Medications = 'medications',
  MusicRecognition = 'musicRecognition',
  Translate = 'translate',
  Swell = 'swell',
  Tides = 'tides',
}

export const WFTopWidgetType = {
  [WatchFaceType.Photo]: [
    WFWidgetType.Off,
    WFWidgetType.Alarm,
    WFWidgetType.TodaysDate,
    WFWidgetType.YourSchedule,
    WFWidgetType.Compass,
    WFWidgetType.Elevation,
    WFWidgetType.Battery,
    WFWidgetType.HeartRate,
    WFWidgetType.SoundLevels,
    WFWidgetType.Reminders,
    WFWidgetType.Stopwatch,
    WFWidgetType.Timer,
    WFWidgetType.Timer1min,
    WFWidgetType.Timer3min,
    WFWidgetType.Timer5min,
    WFWidgetType.Timer10min,
    WFWidgetType.Timer15min,
    WFWidgetType.Timer30min,
    WFWidgetType.Timer1hr,
    WFWidgetType.Timer2hr,
    WFWidgetType.Weather,
    WFWidgetType.Wind,
    WFWidgetType.Rain,
    WFWidgetType.UvIndex,
    WFWidgetType.SunRiseSet,
  ],
  [WatchFaceType.Animated]: [
    WFWidgetType.Off,
    WFWidgetType.Alarm,
    WFWidgetType.TodaysDate,
    WFWidgetType.YourSchedule,
    WFWidgetType.Compass,
    WFWidgetType.Elevation,
    WFWidgetType.HeartRate,
    WFWidgetType.Reminders,
    WFWidgetType.Stopwatch,
    WFWidgetType.Timer,
    WFWidgetType.Timer1min,
    WFWidgetType.Timer3min,
    WFWidgetType.Timer5min,
    WFWidgetType.Timer10min,
    WFWidgetType.Timer15min,
    WFWidgetType.Timer30min,
    WFWidgetType.Timer1hr,
    WFWidgetType.Timer2hr,
    WFWidgetType.Weather,
    WFWidgetType.Wind,
    WFWidgetType.Rain,
    WFWidgetType.UvIndex,
    WFWidgetType.Battery,
    WFWidgetType.SoundLevels,
    WFWidgetType.SunRiseSet,
  ],
  [WatchFaceType.Portrait]: [
    WFWidgetType.Off,
    WFWidgetType.TodaysDate,
  ],
  [WatchFaceType.Parmesan]: [
    WFWidgetType.Off,
    WFWidgetType.Activity,
    WFWidgetType.AirQuality,
    WFWidgetType.Alarm,
    WFWidgetType.AudioBooks,
    WFWidgetType.Battery,
    WFWidgetType.TodaysDate,
    WFWidgetType.YourSchedule,
    WFWidgetType.Compass,
    WFWidgetType.Elevation,
    WFWidgetType.Weather,
    WFWidgetType.FindDevices,
    WFWidgetType.FindItems,
    WFWidgetType.FindPeople,
    WFWidgetType.HeartRate,
    WFWidgetType.Humidity,
    WFWidgetType.Medications,
    WFWidgetType.Messages,
    WFWidgetType.MusicRecognition,
    WFWidgetType.Rain,
    WFWidgetType.Reminders,
    WFWidgetType.SoundLevels,
    WFWidgetType.Stopwatch,
    WFWidgetType.Timer,
    WFWidgetType.Timer1min,
    WFWidgetType.Timer3min,
    WFWidgetType.Timer5min,
    WFWidgetType.Timer10min,
    WFWidgetType.Timer15min,
    WFWidgetType.Timer30min,
    WFWidgetType.Timer1hr,
    WFWidgetType.Timer2hr,
    WFWidgetType.Translate,
    WFWidgetType.UvIndex,
    WFWidgetType.Wind,
    WFWidgetType.SunRiseSet,
    WFWidgetType.Swell,
    WFWidgetType.Tides,
  ]
}

export const WFBottomWidgetType = {
  [WatchFaceType.Photo]: [
    WFWidgetType.Off,
    WFWidgetType.Activity,
    WFWidgetType.Alarm,
    WFWidgetType.MoonPhase,
    WFWidgetType.AudioBooks,
    WFWidgetType.TodaysDate,
    WFWidgetType.YourSchedule,
    WFWidgetType.Compass,
    WFWidgetType.Elevation,
    WFWidgetType.CompassElevation,
    WFWidgetType.Battery,
    WFWidgetType.HeartRate,
    WFWidgetType.Messages,
    WFWidgetType.Music,
    WFWidgetType.SoundLevels,
    WFWidgetType.Podcasts,
    WFWidgetType.Reminders,
    WFWidgetType.Stopwatch,
    WFWidgetType.Timer,
    WFWidgetType.Timer1min,
    WFWidgetType.Timer3min,
    WFWidgetType.Timer5min,
    WFWidgetType.Timer10min,
    WFWidgetType.Timer15min,
    WFWidgetType.Timer30min,
    WFWidgetType.Timer1hr,
    WFWidgetType.Timer2hr,
    WFWidgetType.Weather,
    WFWidgetType.Wind,
    WFWidgetType.Rain,
    WFWidgetType.UvIndex,
    WFWidgetType.Workout,
    WFWidgetType.SunRiseSet,
  ],
  [WatchFaceType.Animated]: [
    WFWidgetType.Off,
    WFWidgetType.Activity,
    WFWidgetType.Alarm,
    WFWidgetType.MoonPhase,
    WFWidgetType.AudioBooks,
    WFWidgetType.TodaysDate,
    WFWidgetType.YourSchedule,
    WFWidgetType.Compass,
    WFWidgetType.Elevation,
    WFWidgetType.CompassElevation,
    WFWidgetType.Battery,
    WFWidgetType.HeartRate,
    WFWidgetType.Messages,
    WFWidgetType.Music,
    WFWidgetType.SoundLevels,
    WFWidgetType.Podcasts,
    WFWidgetType.Reminders,
    WFWidgetType.Stopwatch,
    WFWidgetType.Timer,
    WFWidgetType.Timer1min,
    WFWidgetType.Timer3min,
    WFWidgetType.Timer5min,
    WFWidgetType.Timer10min,
    WFWidgetType.Timer15min,
    WFWidgetType.Timer30min,
    WFWidgetType.Timer1hr,
    WFWidgetType.Timer2hr,
    WFWidgetType.Weather,
    WFWidgetType.Wind,
    WFWidgetType.Rain,
    WFWidgetType.UvIndex,
    WFWidgetType.Workout,
    WFWidgetType.SunRiseSet,
  ],
  [WatchFaceType.Portrait]: [
    WFWidgetType.Off,
    WFWidgetType.Activity,
    WFWidgetType.Alarm,
    WFWidgetType.MoonPhase,
    WFWidgetType.AudioBooks,
    WFWidgetType.TodaysDate,
    WFWidgetType.YourSchedule,
    WFWidgetType.Compass,
    WFWidgetType.CompassElevation,
    WFWidgetType.Elevation,
    WFWidgetType.Battery,
    WFWidgetType.HeartRate,
    WFWidgetType.Messages,
    WFWidgetType.Music,
    WFWidgetType.SoundLevels,
    WFWidgetType.Podcasts,
    WFWidgetType.Reminders,
    WFWidgetType.Timer,
    WFWidgetType.Timer1min,
    WFWidgetType.Timer3min,
    WFWidgetType.Timer5min,
    WFWidgetType.Timer10min,
    WFWidgetType.Timer15min,
    WFWidgetType.Timer30min,
    WFWidgetType.Timer1hr,
    WFWidgetType.Timer2hr,
    WFWidgetType.Weather,
    WFWidgetType.Wind,
    WFWidgetType.Rain,
    WFWidgetType.UvIndex,
    WFWidgetType.Workout,
    WFWidgetType.SunRiseSet,
  ],
  [WatchFaceType.Parmesan]: [
    WFWidgetType.Off,
    WFWidgetType.Activity,
    WFWidgetType.AirQuality,
    WFWidgetType.Alarm,
    WFWidgetType.AudioBooks,
    WFWidgetType.Battery,
    WFWidgetType.TodaysDate,
    WFWidgetType.YourSchedule,
    WFWidgetType.Compass,
    WFWidgetType.Elevation,
    WFWidgetType.Weather,
    WFWidgetType.FindDevices,
    WFWidgetType.FindItems,
    WFWidgetType.FindPeople,
    WFWidgetType.HeartRate,
    WFWidgetType.Humidity,
    WFWidgetType.Medications,
    WFWidgetType.Messages,
    WFWidgetType.MusicRecognition,
    WFWidgetType.Rain,
    WFWidgetType.Reminders,
    WFWidgetType.SoundLevels,
    WFWidgetType.Stopwatch,
    WFWidgetType.Timer,
    WFWidgetType.Timer1min,
    WFWidgetType.Timer3min,
    WFWidgetType.Timer5min,
    WFWidgetType.Timer10min,
    WFWidgetType.Timer15min,
    WFWidgetType.Timer30min,
    WFWidgetType.Timer1hr,
    WFWidgetType.Timer2hr,
    WFWidgetType.Translate,
    WFWidgetType.UvIndex,
    WFWidgetType.Wind,
    WFWidgetType.SunRiseSet,
    WFWidgetType.Swell,
    WFWidgetType.Tides,
  ]
}

export const supportedWfPhotoMimeTypes = [
  'image/png',
  'image/jpeg',
  'image/heic',
];

export const supportedWfAnimateMimeTypes = [
  'image/gif',
  'video/quicktime',
  'video/mp4'
];

export enum WFComplicationType {
  Infograph = 'infograph',
  California = 'california',
  Meridian = 'meridian',
  InfographModular = 'infographModular',
  InfographModularColored = 'infographModularColored', // iOS 16 Only
  XLarge = 'xLarge',
  XLargeColored = 'xLargeColored', // iOS 16 Only
  ModularCompact = 'modularCompact',
  ModularCompactColored = 'modularCompactColored', // iOS 16 Only
  ModularDuo = 'modularDuo', // SE7 and hight (no SE & SE2)
}

export enum ComplicationSlotType {
  SubdialTop = 'subdialTop',
  SubdialLeft = 'subdialLeft',
  SubdialRight = 'subdialRight',
  SubdialBottom = 'subdialBottom',
  Bezel = 'bezel',
  Slot1 = 'slot1',
  Slot2 = 'slot2',
  Slot3 = 'slot3',
  TopLeft = 'topLeft',
  BottomLeft = 'bottomLeft',
  BottomCenter = 'bottomCenter',
  BottomRight = 'bottomRight',
  Top = 'top',
  Center = 'center',
  Bottom = 'bottom',
}

export enum ComplicationSystemSlotType {
  SystemSubdialTop = 'systemSubdialTop',
  SystemSubdialLeft = 'systemSubdialLeft',
  SystemSubdialRight = 'systemSubdialRight',
  SystemSubdialBottom = 'systemSubdialBottom',
  SystemBezel = 'systemBezel',
  SystemSlot1 = 'systemSlot1',
  SystemSlot2 = 'systemSlot2',
  SystemSlot3 = 'systemSlot3',
  SystemTopLeft = 'systemTopLeft',
  SystemBottomLeft = 'systemBottomLeft',
  SystemBottomCenter = 'systemBottomCenter',
  SystemBottomRight = 'systemBottomRight',
  SystemTop = 'systemTop',
  SystemCenter = 'systemCenter',
  SystemBottom = 'systemBottom',
}

export enum CustomColorType {
  Multicolor = 'multicolor',
  StandardRed = 'standard.red',
  StandardOrange = 'standard.orange',
  StandardLightOrange = "standard.lightOrange",
  StandardGreen = 'standard.green',
  StandardLightGreen = "standard.lightGreen",
  StandardYellow = "standard.yellow",
  StandardLightYellow = "standard.lightYellow",
  StandardBlue = 'standard.blue',
  StandardLightBlue = 'standard.lightBlue',
  StandardNavy = 'standard.navy',
  StandardPurple = 'standard.purple',
  StandardLightPurple = 'standard.lightPurple',
  StandardPink = 'standard.pink',
  StandardLightPink = 'standard.lightPink',
  StandardPlum = 'standard.plum',
  StandardStone = 'standard.stone',
  StandardGray = 'standard.fray',
  StandardWarmGray = 'standard.warmGray',
  StandardCream = 'standard.cream',
  GossamerColor1 = 'gossamer.color1', // iOS 16 Only 
  GossamerColor2 = 'gossamer.color2', // iOS 16 Only 
  GossamerColor3 = 'gossamer.color3', // iOS 16 Only 
  GossamerColor4 = 'gossamer.color4', // IOS 16 Only 
  GossamerColor5 = 'gossamer.color5', // IOS 16 Only 
  GossamerColor6 = 'gossamer.color6', // iOS 16 Only 
  GossamerColor7 = 'gossamer.color7', // iOS 16 Only 
  GossamerColor8 = 'gossamer.color8', // iOS 16 Only

  SeasonSuccelent = 'seasons.fall2022.succulent', // iOS 16 Only 
  SeasonSunglow = 'seasons.fall2022.sunglow', // iOS 16 Only 
  SeasonBrightGreen = 'seasons.spring2022.green3', // iOS 16 Only 
  SeasonFlamingo = 'seasons.spring2022.pink6t', // iOS 16 Only

  SeasonAbyssBlue = 'seasons.fall2021.blue11',
  SeasonElectricOrange = 'ElectricOrange',
  SeasonCloudBlue = 'CloudBlue',
  SeasonWhite = 'seasons.spring2015.white', // infographModulrar Only
}

export enum BackgroundColorType {
  WhiteMeridian = "style 2",
  BlackMeridian = "style 1",

  WhiteInfograph = "white",
  BlackInfograph = "whistler black",

  BlackModularCompact = "whistler black",

  WhiteCalifornia = "style 1",
  BlackCalifornia = "style 3",
  Rainbow = "style 10",
  Ceramic = "style 8",
  Gold = "style 9",
  NavyBlue = "style 4",
  Ivory = "style 2",
  PalePink = "style 5",
  Evergreen = "style 11",
  Mango = "style 12",
  Slate = "style 13",
  Watermelon = "style 15",
  Red = "standard.red",
  Orange = "standard.orange",
  Green = "standard.green",
  LightPink = "standard lightPink",
}

export enum WFStyleType {
  Digital = 'digital',
  Analog = 'analog',
}

export enum WFFaceStyle {
  Circular = 'circular',
  Fullscreen = 'fullscreen',
}

export enum WFFaceType {
  Stripes = "style 1",
  Roman = "style 2",
  California = "style 3",
  Arabic = "style 4",
  Arabicindic = "style 5",
  Devanagari = "style 6",
  Chinese = "style 8",
}

export enum WFFamilyType {
  Circular = 'circular',
  Rectangular = 'rectangular',
}

export enum WFInformationType {
  Date = 'date',
}
export interface WatchFace {
  readonly id: string;
  readonly wfType: WatchFaceType;
  readonly status: WFStatus;
  readonly category: Category;
  readonly name: string;

  readonly isFree: boolean;
  readonly isApproved: boolean;

  readonly isLiked: boolean;
  readonly isReported: boolean;

  readonly installsCount: number;
  readonly likesCount: number;
  readonly reportsCount: number;

  readonly minIOSVersion: Array<number>;

  readonly position: number;
  readonly fakeLikes: number;
  readonly fakeInstalls: number;


  readonly styles: any;
  readonly complicationsInfo: any;
  readonly tags: Array<string>;
  readonly searchFilters: Array<SearchFilter>

}

export interface WatchFaceWithReport extends WatchFace {
  readonly reports: Array<ReportWithUser>
}

export interface SystemComplicationPreviewModel {
  readonly familyType: WFFamilyType;
  readonly informationType: WFInformationType;

}

export enum WFParmesanStyle {
  Small = "small",
  Medium = "medium",
  Large = "large",
  XLarge = "xLarge",
}



export const WFTimePositionOptions = {
  [WatchFaceType.Photo]: [...Object.values(WFTimePosition)],
  [WatchFaceType.Animated]: [...Object.values(WFTimePosition)],
  [WatchFaceType.Portrait]: [...Object.values(WFTimePosition)],
  [WatchFaceType.Parmesan]: [...Object.values(WFTimePosition).filter(el => el !== WFTimePosition.Custom) as Array<WFTimePosition>],
  [WatchFaceType.Complication]: [],
}

export const WFTimeStyleOption = {
  [WatchFaceType.Photo]: [],
  [WatchFaceType.Complication]: [],
  [WatchFaceType.Animated]: [...Object.values(WFTimeStyle)],
  [WatchFaceType.Portrait]: [...Object.values(WFTimeStyle)],
  [WatchFaceType.Parmesan]: [...Object.values(WFTimeStyle).filter(el => el !== WFTimeStyle.Photo) as Array<WFTimeStyle>],
}

export enum WFTimeColor {
  BlueFog = "seasons.spring2022.blue2",
  Cypress = "seasons.fall2023.cypress",
  Guava = "seasons.fall2023.guava",
  Taupe = "seasons.fall2023.taupe",
  Sunglow = "seasons.fall2022.sunglow",
  Beryl = "seasons.winter2019.beryl",
  PacificGreen = "seasons.winter2018.pacificGreen",
  White = "seasons.spring2015.white",
  Flamingo = "seasons.spring2022.pink6t",
  Pomegranate = "seasons.winter2019.pomegranate",
  DragonFruit = "seasons.summer2019.dragonFruit",
  Hibiscus = "seasons.winter2018.hibiscus",
  UltraViolet = "seasons.fall2017.violet",
  FlashLight = "seasons.spring2018.flashLight",
  GreyDarkest = "standard.gray:1.00",// - 656469
  Grey = "standard.gray",// - 656469 - 9C9BA3
  Cream = "standard.cream",// - 656469 - FEEDD1
  CreamDarkest = "standard.cream:1.00",// - 656469 - A19275
  WarmGrey = "standard.warmGray",// - 656469 - AEA191
  WarmGreyDarkest = "standard.warmGray:1.00",// - 656469 - 66615B
  Stone = "standard.stone",// - 656469 - 827161
  StoneDarkest = "standard.stone:1.00",// - 656469 - 5F5144
  Plum = "standard.plum",// - 656469 - 93424B
  PlumDarkest = "standard.plum:1.00",// - 656469 - 8C2331
  PinkDarkest = "standard.pink:1.00",// - 656469 - AF0834
  Pink = "standard.pink",// - 656469 - FF1553
  LightPinkDarkest = "standard.lightPink:1.00",// - 656469 - BA0044
  LightPink = "standard.lightPink",// - 656469 - EC4578
  LightPinkLightest = "standard.lightPink:0.00",// - 656469 - FF90B4
  LightPurple = "standard.lightPurple",// - 656469 - B9A5E4
  Purple = "standard.purple",// - 656469 - 7A7AFE
  LightPurpleDarkest = "standard.lightPurple:1.00",// - 656469 - 7959BC
  PurpleDarkest = "standard.purple:1.00",// - 656469 - 414199
  NavyDarkest = "standard.navy:1.00",// - 656469 - 333E7C
  Navy = "standard.navy",// - 656469 - 56609E
  BlueDarkest = "standard.blue:1.00",// - 656469 - 1968AD
  Blue = "standard.blue",// - 656469 - 2293F9
  BlueLightest = "standard.blue:0.00",// - 656469 - 8AC7FF
  LightBlueLightest = "standard.lightBlue:0.00",// - 656469 - ACE5FF
  LightBlue = "standard.lightBlue",// - 656469 - 5DC7F9
  LightBlueDarkest = "standard.lightBlue:1.00",// - 656469 - 316984
  GreenLightest = "standard.green:0.00",// - 656469 - A4FFC2
  Green = "standard.green",// - 656469 - 02DE72
  GreenDarkest = "standard.green:1.00",// - 656469 - 0F703A
  LightGreenDarkest = "standard.lightGreen:1.00",// - 656469 - 518145
  LightGreen = "standard.lightGreen",// - 656469 - B2F3A1
  LightGreenLightest = "standard.lightGreen:0.00",// - 656469 - D2F1C7
  LightYellowLightest = "standard.lightYellow:0.00",// - 656469 - FCF4C5
  LightYellow = "standard.lightYellow",// - 656469 - FCEE7B
  LightYellowDarkest = "standard.lightYellow:1.00",// - 656469 - FFBC01
  YellowDarkest = "standard.yellow:1.00",// - 656469 - 6F663D
  Yellow = "standard.yellow",// - 656469 - FFDE45
  YellowLightest = "standard.yellow:0.00",// - 656469 - FDEEA9
  LightOrangeLightest = "standard.lightOrange:0.00",// - 656469 - FECC8D
  LightOrange = "standard.lightOrange",// - 656469 - FF9500
  LightOrangeDarkest = "standard.lightOrange:1.00",// - 656469 - B93F00
  Orange_Darkest = "standard.orange:1.00",// - 656469 - A5290D
  Orange = "standard.orange",// - 656469 - F76647
  OrangeLightest = "standard.orange:0.00",// - 656469 - FFCCBE
  Red = "standard.red",// - 656469 - FE2A43
  RedDarkest = "standard.red:1.00",// - 656469 - C10011
}

export const WFTimeColorLabel = {
  [WFTimeColor.BlueFog]: "blueFog",
  [WFTimeColor.Cypress]: "cypress",
  [WFTimeColor.Guava]: "guava",
  [WFTimeColor.Taupe]: "taupe",
  [WFTimeColor.Sunglow]: "sunglow",
  [WFTimeColor.Beryl]: "beryl",
  [WFTimeColor.PacificGreen]: "pacificGreen",
  [WFTimeColor.Red]: "red",
  [WFTimeColor.White]: "white",
  [WFTimeColor.Green]: "green",
  [WFTimeColor.Grey]: "gray",
  [WFTimeColor.Pink]: "pink",
  [WFTimeColor.Flamingo]: "flamingo",
  [WFTimeColor.Pomegranate]: "pomegranate",
  [WFTimeColor.DragonFruit]: "dragonFruit",
  [WFTimeColor.Hibiscus]: "hibiscus",
  [WFTimeColor.UltraViolet]: "ultraViolet",
  [WFTimeColor.Orange]: "orange",
  [WFTimeColor.Purple]: "purple",
  [WFTimeColor.LightOrange]: "lightOrange",
  [WFTimeColor.FlashLight]: "flashLight",

  [WFTimeColor.GreyDarkest]: "GreyDarkest",
  [WFTimeColor.Grey] : "Grey",
  [WFTimeColor.Cream] : "Cream",
  [WFTimeColor.CreamDarkest] : "CreamDarkest",
  [WFTimeColor.WarmGrey] : "WarmGrey",
  [WFTimeColor.WarmGreyDarkest] : "WarmGreyDarkest",
  [WFTimeColor.Stone] : "Stone",
  [WFTimeColor.StoneDarkest] : "StoneDarkest",
  [WFTimeColor.Plum] : "Plum",
  [WFTimeColor.PlumDarkest] : "PlumDarkest",
  [WFTimeColor.PinkDarkest] : "PinkDarkest",
  [WFTimeColor.Pink] : "Pink",
  [WFTimeColor.LightPinkDarkest] : "LightPinkDarkest",
  [WFTimeColor.LightPink] : "LightPink",
  [WFTimeColor.LightPinkLightest] : "LightPinkLightest",
  [WFTimeColor.LightPurple] : "LightPurple",
  [WFTimeColor.Purple] : "Purple",
  [WFTimeColor.LightPurpleDarkest] : "LightPurpleDarkest",
  [WFTimeColor.PurpleDarkest] : "PurpleDarkest",
  [WFTimeColor.NavyDarkest] : "NavyDarkest",
  [WFTimeColor.Navy] : "Navy",
  [WFTimeColor.BlueDarkest] : "BlueDarkest",
  [WFTimeColor.Blue] : "Blue",
  [WFTimeColor.BlueLightest] : "BlueLightest",
  [WFTimeColor.LightBlueLightest] : "LightBlueLightest",
  [WFTimeColor.LightBlue] : "LightBlue",
  [WFTimeColor.LightBlueDarkest] : "LightBlueDarkest",
  [WFTimeColor.GreenLightest] : "GreenLightest",
  [WFTimeColor.Green] : "Green",
  [WFTimeColor.GreenDarkest] : "GreenDarkest",
  [WFTimeColor.LightGreenDarkest]: "LightGreenDarkest",
  [WFTimeColor.LightGreen] : "LightGreen",
  [WFTimeColor.LightGreenLightest] : "LightGreenLightest",
  [WFTimeColor.LightYellowLightest] : "LightYellowLightest",
  [WFTimeColor.LightYellow] : "LightYellow",
  [WFTimeColor.LightYellowDarkest] : "LightYellowDarkest",
  [WFTimeColor.YellowDarkest] : "YellowDarkest",
  [WFTimeColor.Yellow] : "Yellow",
  [WFTimeColor.YellowLightest] : "YellowLightest",
  [WFTimeColor.LightOrangeLightest] : "LightOrangeLightest",
  [WFTimeColor.LightOrangeDarkest] : "LightOrangeDarkest",
  [WFTimeColor.Orange_Darkest] : "Orange_Darkest",
  [WFTimeColor.Orange] : "Orange",
  [WFTimeColor.OrangeLightest] : "OrangeLightest",
  [WFTimeColor.Red] : "Red",
  [WFTimeColor.RedDarkest] : "RedDarkest",
}

export const WFTimeColorHex = {
  [WFTimeColor.BlueFog]: "#8FB1C9",
  [WFTimeColor.Cypress]: "#737E5A",
  [WFTimeColor.Guava]: "#FF505A",
  [WFTimeColor.Taupe]: "#7A5B4B",
  [WFTimeColor.Sunglow]: "#FFBF52",
  [WFTimeColor.Beryl]: "#D5F2BD",
  [WFTimeColor.PacificGreen]: "#007F9E",
  [WFTimeColor.Red]: "#BC1A30",
  [WFTimeColor.White]: "#FFFFFF",
  [WFTimeColor.Green]: "#8CE329",
  [WFTimeColor.Grey]: "#656469",
  [WFTimeColor.Pink]: "#FF6A77",
  [WFTimeColor.Flamingo]: "#FF6B8B",
  [WFTimeColor.Pomegranate]: "#E02A5F",
  [WFTimeColor.DragonFruit]: "#F44A7E",
  [WFTimeColor.Hibiscus]: "#FD1E58",
  [WFTimeColor.UltraViolet]: "#7157BE",
  [WFTimeColor.Orange]: "#FF6411",
  [WFTimeColor.Purple]: "#997CF7",
  [WFTimeColor.LightOrange]: "#FF9500",
  [WFTimeColor.FlashLight]: "#F7EC00",

  [WFTimeColor.GreyDarkest]: "#656469",
  [WFTimeColor.Grey] : "#9C9BA3",
  [WFTimeColor.Cream] : "#FEEDD1",
  [WFTimeColor.CreamDarkest] : "#A19275",
  [WFTimeColor.WarmGrey] : "#AEA191",
  [WFTimeColor.WarmGreyDarkest] : "#66615B",
  [WFTimeColor.Stone] : "#827161",
  [WFTimeColor.StoneDarkest] : "#5F5144",
  [WFTimeColor.Plum] : "#93424B",
  [WFTimeColor.PlumDarkest] : "#8C2331",
  [WFTimeColor.PinkDarkest] : "#AF0834",
  [WFTimeColor.Pink] : "#FF1553",
  [WFTimeColor.LightPinkDarkest] : "#BA0044",
  [WFTimeColor.LightPink] : "#EC4578",
  [WFTimeColor.LightPinkLightest] : "#FF90B4",
  [WFTimeColor.LightPurple] : "#B9A5E4",
  [WFTimeColor.Purple] : "standard.purple",
  [WFTimeColor.LightPurpleDarkest] : "#7959BC",
  [WFTimeColor.PurpleDarkest] : "#414199",
  [WFTimeColor.NavyDarkest] : "#333E7C",
  [WFTimeColor.Navy] : "#56609E",
  [WFTimeColor.BlueDarkest] : "#1968AD",
  [WFTimeColor.Blue] : "#2293F9",
  [WFTimeColor.BlueLightest] : "#8AC7FF",
  [WFTimeColor.LightBlueLightest] : "#ACE5FF",
  [WFTimeColor.LightBlue] : "#5DC7F9",
  [WFTimeColor.LightBlueDarkest] : "#316984",
  [WFTimeColor.GreenLightest] : "#A4FFC2",
  [WFTimeColor.Green] : "#02DE72",
  [WFTimeColor.GreenDarkest] : "#0F703A",
  [WFTimeColor.LightGreenDarkest]: "#518145",
  [WFTimeColor.LightGreen] : "#B2F3A1",
  [WFTimeColor.LightGreenLightest] : "#D2F1C7",
  [WFTimeColor.LightYellowLightest] : "#FCF4C5",
  [WFTimeColor.LightYellow] : "#FCEE7B",
  [WFTimeColor.LightYellowDarkest] : "#FFBC01",
  [WFTimeColor.YellowDarkest] : "#6F663D",
  [WFTimeColor.Yellow] : "#FFDE45",
  [WFTimeColor.YellowLightest] : "#FDEEA9",
  [WFTimeColor.LightOrangeLightest] : "#FECC8D",
  [WFTimeColor.LightOrange] : "#FF9500",
  [WFTimeColor.LightOrangeDarkest] : "#B93F00",
  [WFTimeColor.Orange_Darkest] : "#A5290D",
  [WFTimeColor.Orange] : "#F76647",
  [WFTimeColor.OrangeLightest] : "#FFCCBE",
  [WFTimeColor.Red] : "#FE2A43",
  [WFTimeColor.RedDarkest] : "#C10011",
}